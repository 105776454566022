import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import InterCO from './sections/inter-co/_index'
import Explanations from './sections/explanations/_index'

import { Wrapper } from './style'

const Securities = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <InterCO />
        <Explanations />
      </Layout>
    </Wrapper>
  )
}

export default Securities
