import styled, { css } from 'styled-components'

type UpProps = {
  up: boolean;
}

type ButtonData = {
  active: boolean;
}

type DataLabelProps = {
  current: boolean;
}

export const Section = styled.section`

`

export const Button = styled.button`
  border: none;
  background: none;
  display: block ;
  width: 100%;
  height: 20px;
  font-weight: ${(props: DataLabelProps) => props.current ? '700' : '400'};
  color: ${(props: DataLabelProps) => props.current ? '#6A6C72' : '#B6B7BB'};


`

export const Box = styled.div`
  border-radius: 8px;
  padding: 8px  16px 8px  16px ;
  background:  #FFF2E7;
  color: #181818;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  width: 107px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`
export const Select = styled.div`
  position: relative;
  margin-top: 24px;
  position: relative ;


  svg {
    position: absolute;
    right: 10px;
  }


  .icon {
    transition: transform 0.5s ease-in-out;
  }

`

export const ButtonData = styled.button`
   background: none;
    padding: 8px 16px ;
    border: 1px solid #DEDFE4;
    width: 79px;
    height: 32px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    z-index: 2;
    background: ${(props: ButtonData) => props.active ? '#161616' : '#ffffff'};
    position: relative;


    span {
      color: ${(props: ButtonData) => props.active ? '#ffffff' : '#161616'};
    }

`

export const DataLabel = styled.div`

`

export const ButtonBox = styled.div`
  border-radius: 0px 0px 15px 15px;
  width: 79px;
  height:  0;
  background: #ffffff ;
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 0;
  border: 1px solid #DEDFE4;
  overflow: hidden;
  padding: 0;
  transition: height 0.5s ease-in-out;
  ${({ up }: UpProps) => up && css`
    height: 100px;
    padding-top: 16px;
  `}

`

export const Container = styled.div`
  background: #F5F6FA;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  margin-top: 24px;
  padding: 16px;
  border-bottom: 2px solid var(--light-neutral-grayscale-200, #DEDFE4);

`
export const Border = styled.hr`
  border: 1px solid #DEDFE4;
`
export const Displaying = styled.div`
    width: 128px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #DEDFE4;
    display: flex;
    align-items: center;
    justify-content: center;



  select{
    border: none;
    appearance: none;
    padding: 6px;
    border-radius: 20px;

  }
`
export const Pagination = styled.button`
  border-radius: 8px;
  border: 1px solid #DEDFE4;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff ;

`
export const Page = styled.div`
  border-radius: 4px;
  width: 80px;
  height: 48px;
  background: #F5F6FA;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px

`
