import React, { useEffect, useState } from 'react'

import securitiesJSON from 'src/assets/data/securities/securities.json'
import buttonJSON from '../../assets/data/select.json'

import {
  Box,
  Section,
  Select,
   Container,
   Border,
   Displaying,
   Pagination,
    Page,
    Button,
    ButtonBox,
    ButtonData,
  } from './style'
import ChevronDown from '@interco/icons/build-v4/orangeds/MD/chevron-down'
import PdfFile from '@interco/icons/build-v4/orangeds/MD/pdf-file'
import ChevronLeft from '@interco/icons/build-v4/orangeds/SM/chevron-left'
import ChevronRight from '@interco/icons/build-v4/orangeds/SM/chevron-right'
import ChevronUp from '@interco/icons/build-v4/orangeds/MD/chevron-up'

type SecuritiesProps = {
  title: string;
  pdf: string;
  createdAt: string;
}

type ButtonProps = {
  date: string;
  current: boolean;
}

const InterCO = () => {
  const [ up, setUp ] = useState(false)
  const [ select, setSelect ] = useState('Data')
  const [ data, setData ] = useState(securitiesJSON)
  const [ amount, setAmount ] = useState(10)
  const [ offset, setOffset ] = useState(0)
  const [ page, setPage ] = useState(1)

  useEffect(() => {
    if (select !== 'Data') {
      const newData = securitiesJSON.filter((item: SecuritiesProps) => {
        return item.createdAt.includes(select)
      })
      setData(newData)
    }
  }, [ select ])

  useEffect(() => {
    setOffset(amount * (page - 1))
  }, [ amount, page ])

  function getNumberOfPages () {
    return Math.ceil(data.length / amount)
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Box>Documentos</Box>
            <h1
              className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'
            >
              Inter & Co Securities
            </h1>
            <Select>
              <ButtonBox up={up}>
                {
                buttonJSON.map((item: ButtonProps) => (
                  <Button
                    className='fs-10 lh-12 text-grayscale--400'
                    current={item.current}
                    onClick={() => {
                      setSelect(item.date)
                      setUp(false)
                    }}
                    key={item.date}
                  >{item.date}
                  </Button>

                ))
              }
              </ButtonBox>
              <ButtonData onClick={() => setUp(!up)} active={select !== 'Data'}>
                <span className='fs-12 lh-14 text-grayscale--500'>{select}</span>
                {
                  up ? (
                    <ChevronUp height={16} width={16} color='#161616' />
                ) : (
                  <ChevronDown height={16} width={16} color='#161616' className='icon' />
                )
              }
              </ButtonData>
            </Select>
            <Container>
              <div className='row'>
                <div className='col-6 d-flex'>
                  <p className='fs-12 lh-14 fw-700 text-grayscale--400 mr-1'>Descrição</p>
                  <ChevronDown height={16} width={16} color='#161616' />
                </div>
                <div className='col-2 d-flex'>
                  <p className='fs-12 lh-14 fw-700 text-grayscale--400 mr-1'>Data</p>
                  <ChevronDown height={16} width={16} color='#161616' />
                </div>
                <div className='col-4 d-flex justify-content-end'>
                  <p className='fs-12 lh-14 fw-700 text-grayscale--400 mr-1'>Download</p>
                </div>
              </div>
            </Container>
            <div className='col-12'>
              {
              data.slice(offset, offset + amount).map((item: SecuritiesProps) => (
                <>
                  <div className='row mt-4 px-3' key={item.title}>
                    <div className='col-6 d-flex'>
                      <p className='fs-14 lh-16 text-grayscale--500 fw-700'>{item.title}</p>
                    </div>
                    <div className='col-2'>
                      <p className='fs-14 lh-16 text-grayscale--500 fw-700'>{item.createdAt}</p>
                    </div>
                    <div className='col-4 d-flex justify-content-end'>
                      <a href={item.pdf}>
                        <PdfFile height={24} width={24} color='#FF7A00' className='mr-3' />
                      </a>
                    </div>
                  </div>
                  <Border />
                </>
              ),
            )}
            </div>
            <div className='row d-flex align-items-center justify-content-between'>
              <div className='col-6 col-md-3 order-2 order-md-1'>
                <Displaying className='fs-14 lh-16 text-grayscale--500 fw-400'>
                  <select
                    defaultValue={10}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      setAmount(parseInt(event.target.value))
                      setPage(1)
                    }}
                  >
                    <option className='fs-14 lh-16 text-grayscale--500 fw-400' value='5'>Exibindo 5</option>
                    <option value='10'>Exibindo 10</option>
                    <option value='15'>Exibindo 15</option>
                    <option value='20'>Exibindo 20</option>
                  </select>
                  <ChevronDown height={16} width={16} color='#161616' />
                </Displaying>
              </div>
              <div className='col-12 col-md-5 order-1 order-md-2'>
                <div className='d-flex align-items-center justify-content-between'>
                  <Pagination onClick={() => setPage(page - 1)} disabled={page === 1}>
                    <ChevronLeft
                      height={16}
                      width={16}
                      color={` ${page > 1 ? '#ff7a00' : '#B6B7BB'}`}
                    />
                  </Pagination>
                  <p className='fs-16 lh-19 fw-400 text-grayscale--500 mb-0'>Página</p>
                  <Page>{page}</Page>
                  <p className='fs-16 lh-19 fw-400 text-grayscale--500 mb-0'>de {getNumberOfPages()}</p>
                  <Pagination
                    className='ml-2' onClick={() => setPage(page + 1)}
                    disabled={page === getNumberOfPages()}
                  >
                    <ChevronRight
                      height={16}
                      width={16}
                      color={` ${getNumberOfPages() > page ? '#ff7a00' : '#B6B7BB'}`}
                    />
                  </Pagination>
                </div>
              </div>
              <div className='col-6 col-md-4 col-lg-3 order-3 order-md-3'>
                <p className='fs-16 lh-19 fw-400 text-grayscale--500 mb-0'>
                  Resultado: {offset + 1 } - {amount * page} de {getNumberOfPages()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InterCO
